<template>
  <footer class="footer">
    <div class="footer-content">
      <div class="footer-column">
        <ul class="footer-content__list">
          <li class="footer-content__list--item" v-for="(item, index) in footer.column1" :key="index">
            <router-link :to="item.route">{{ item.name }}</router-link>
          </li>
        </ul>
      </div>
      <div class="footer-column">
        <ul class="footer-content__list">
          <li class="footer-content__list--item" v-for="(item, index) in footer.column2" :key="index">
            <router-link :to="item.route">{{ item.name }}</router-link>
          </li>
        </ul>
      </div>
      <div class="footer-column">
        <div class="footer-content__list">
          <div class="box-dropdown">
            <div class="lag-container">
              <div class="container-lag">
                <div class="container-langs" v-if="this.$i18n.locale == 'en'">
                  <div class="lang" @click="changeLanguage('en')"> English <img src="../assets/images/engl.png"
                      alt="english"></div>
                  <div class="lang" @click="changeLanguage('es')"> Español <img src="../assets/images/spanish.png"
                      alt="spanish"></div>
                </div>
                <div class="container-langs" v-if="this.$i18n.locale == 'es'">
                  <div class="lang" @click="changeLanguage('es')"> Español <img src="../assets/images/spanish.png"
                      alt="spanish"></div>
                  <div class="lang" @click="changeLanguage('en')"> English <img src="../assets/images/engl.png"
                      alt="english"></div>
                </div>
                <svg class="flecha" xmlns="http://www.w3.org/2000/svg" width="7" height="14" viewBox="0 0 10 16"
                  fill="#ff369b">
                  <path d="M1 1L8 8L1 15" stroke="#ff369b" stroke-width="2" stroke-linecap="round" />
                </svg>
                <!-- Resto del contenido de tu componente -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <div class="mobile-nav-container">
    <div class="mobile-nav-container-content">
      <router-link class="mobile-nav-items" v-for="(option, index) in options" :key="index" :to="`/${getOptionRoute(option.route)}`">
        <div class="mobile-nav-img-container">
          <img :src="option.img" :alt="option.alt" width="100%" height="auto" rel="preload">
        </div>
        <span>{{ $t(`options.items.${index}.name`) }}</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { watch } from 'vue';
import { computed } from 'vue'

export default {
  name: "FooterComponent",
  watch: {
    $route(newVar) {
      let pathName = computed(() => newVar);
      this.detectLang(pathName.value.name);
    }
  },
  methods: {


    changeLanguage(locale) {
      
      if (this.$i18n.locale != locale) {
        
        this.$i18n.locale = locale;
        const t = this.$t;
        const currentRoute = this.$router.currentRoute.value;
        if (currentRoute.path !== '/') {
          const translatedPath = `/${t(`routes.${currentRoute.name}`)}`;
          this.$router.push(translatedPath);
        }
      }

    },
    getTranslatedRoutes(t) {

      const routes = this.$router.options.routes.map((route) => ({
        ...route,
        path: route.path !== '/' ? `/${t(`routes.${route.name}`)}` : '/',
      }));

      return routes;
    },
    detectLang(pathName) {
      if (pathName.includes("langes")) {
        this.$i18n.locale = "es";
        this.changeLanguage("es")
      } else if (pathName.includes("langen")) {
        this.$i18n.locale = "en";
        this.changeLanguage("en")
      } else {
        console.log("default")
      }
    }
  },

  setup() {
    const { t, locale } = useI18n();

    const footer = {
      column1: [
        { name: t("footer.column1.0.name"), route: t("footer.column1.0.route") },
        { name: t("footer.column1.1.name"), route: t("footer.column1.1.route") },
        { name: t("footer.column1.2.name"), route: t("footer.column1.2.route") },
        { name: t("footer.column1.3.name"), route: t("footer.column1.3.route") }
      ],
      column2: [
        { name: t("footer.column2.0.name"), route: t("footer.column2.0.route") },
        { name: t("footer.column2.1.name"), route: t("footer.column2.1.route") },
        { name: t("footer.column2.2.name"), route: t("footer.column2.2.route") },
        { name: t("footer.column2.3.name"), route: t("footer.column2.3.route") }
      ]
    };


    watch(locale, () => {
      footer.column1.forEach((item, index) => {
        item.name = t(`footer.column1.${index}.name`);
        item.route = t(`footer.column1.${index}.route`);
      });
      footer.column2.forEach((item, index) => {
        item.name = t(`footer.column2.${index}.name`);
        item.route = t(`footer.column2.${index}.route`);
      });
    });

    const options = [
      {
        route: 'sportsbook',
        img: require('../assets/images/sports.png'),
        alt: 'sportsbook'
      },
      {
        route: 'live-betting',
        img: require('../assets/images/live betting.png'),
        alt: 'live betting'
      },
      {
        route: 'casino',
        img: require('../assets/images/casino.png'),
        alt: 'casino'
      },
      {
        route: 'racebook',
        img: require('../assets/images/racebook.png'),
        alt: 'racebook'
      },
      {
        route: 'props-builder',
        img: require('../assets/images/props-builder.svg'),
        alt: 'Props-Builder'
      }
    ];
    const getOptionRoute = (route) => {
      // busca la ruta correcta basado en locale siempre y cuando se hga fetch del json
      const translatedRoute = t(`options.items.${options.findIndex(opt => opt.route === route)}.route`);
      return translatedRoute || route; // devuelve la ruta original en caso de error
    };
    return {
      footer,
      options,
      getOptionRoute
    }
  },
};
</script>
