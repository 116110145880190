<template>
  <div class="hamburger-menu">
    <ul class="hamburger-menu__list" :class="{ 'is-active': isActive }">
      <li class="hamburger-menu__item" @click="isActive = !isActive" v-for="(item, index) in routes" :key="index">
        <router-link :to="item.route">{{ item.name }}</router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';

export default {
  name: "MenuComponent",
  watch: {
    $route(newVar) {
      let pathName = computed(() => newVar);
      this.detectLang(pathName.value.name);
    }
  },
  methods: {
    changeLanguage(locale) {
      if (this.$i18n.locale != locale) {
        this.$i18n.locale = locale;
        const t = this.$t;
        const currentRoute = this.$router.currentRoute.value;
        if (currentRoute.path !== '/') {
          const translatedPath = `/${t(`routes.${currentRoute.name}`)}`;
          this.$router.push(translatedPath);
        }
      }
    },
    detectLang(pathName) {
      if (pathName.includes("langes")) {
        this.$i18n.locale = "es";
        this.changeLanguage("es")
      } else if (pathName.includes("langen")) {
        this.$i18n.locale = "en";
        this.changeLanguage("en")
      } else {
        console.log("default")
      }
    }
  },
  setup() {
    const { t } = useI18n();

    const routes = computed(() => [
      { name: t("header.routes.0.name"), route: t("header.routes.0.route") },
      { name: t("header.routes.1.name"), route: t("header.routes.1.route") },
      { name: t("header.routes.2.name"), route: t("header.routes.2.route") },
      { name: t("header.routes.3.name"), route: t("header.routes.3.route") },
      { name: t("header.routes.4.name"), route: t("header.routes.4.route") }
    ]);

    return {
      isActive: false,
      routes
    };
  },
};
</script>