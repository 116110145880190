import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../pages/home.vue'),
  },
  {
    path: '/sportsbook',
    name: 'sportsbook-langen',
    component: () => import('../pages/sportsbook.vue'),  
  },
  {
    path: '/deportes',
    name: 'sportsbook-langes',
    component: () => import('../pages/sportsbook.vue'),  
  },
  {
    path: '/casino',
    name: 'casino-langen',
    component: () => import('../pages/casino.vue'),  
  },
  {
    path: '/casino-es',
    name: 'casino-langes',
    component: () => import('../pages/casino.vue'),  
  },
  {
    path: '/racebook',
    name: 'racebook-langen',
    component: () => import('../pages/racebook.vue'),  
  },
  {
    path: '/caballos',
    name: 'caballos-langes',
    component: () => import('../pages/racebook.vue'),  
  },
  {
    path: '/live-betting',
    name: 'live-betting-langen',
    component: () => import('../pages/livebetting.vue'),  
  },
  {
    path: '/apuestas-en-vivo',
    name: 'live-betting-langes',
    component: () => import('../pages/livebetting.vue'),  
  },
  {
    path:'/responsiblegaming',
    name: 'responsiblegaming-langen',
    component: () => import('../pages/responsiblegaming.vue'),
  },
  {
    path:'/juegoresponsable',
    name: 'juegoresponsable-langes',
    component: () => import('../pages/responsiblegaming.vue'),
  },
  {
    path: '/house-rules',
    name: 'house-rules-langen',
    component: () => import('../pages/house-rules.vue'),  
  },
  {
    path: '/reglas-de-la-casa',
    name: 'house-rules-langes',
    component: () => import('../pages/house-rules.vue'),  
  },
  {
    path: '/sport-rules',
    name: 'sports-rules-langen',
    component: () => import('../pages/sport-rules.vue'),  
  },
  {
    path: '/reglas-deportivas',
    name: 'sports-rules-langes',
    component: () => import('../pages/sport-rules.vue'),  
  },
  {
    path: '/forgot-pass',
    name: 'forgot-pass-langen',
    component: () => import('../pages/forgot-pass.vue'),  
  },
  {
    path: '/restablecer-contrasena',
    name: 'restablecer-contrasena-langes',
    component: () => import('../pages/forgot-pass.vue'),  
  },
  {
    path: '/props-builder',
    name: 'props-builder-langen',
    component: () => import('../pages/props-builder.vue'),  
  },
  {
    path: '/props-builder-es',
    name: 'props-builder-langes',
    component: () => import('../pages/props-builder.vue'),  
  },
  {
    path: '/horse-rules',
    name: 'horse-rules-langen',
    component: () => import('../pages/horse-rules.vue'),  
  },
  {
    path: '/reglas-de-caballos',
    name: 'reglas-de-caballos-langes',
    component: () => import('../pages/horse-rules.vue'),  
  },
  {
    path: '/same-game-parlay-rules',
    name: 'same-game-parlay-rules-langen',
    component: () => import('../pages/same-game-parlay-rules.vue'),  
  },
  {
    path: '/reglas-de-parlay-del-mismo-equipo',
    name: 'same-game-parlay-rules-langes',
    component: () => import('../pages/same-game-parlay-rules.vue'),  
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  }
})
export default router